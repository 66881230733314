import { AppState } from './index';

export const initialState: AppState = {
	catFormacao: [],
	catCompetencia: [],
	catIdioma: [],
	catExperiencia: [],
	catPerfil: [],
	catTestePerfil: [],
	showingInteracoes: 'closed',
	showingAgenda: 'closed',
	showingPerfil: false,
	showingMenu: false,
	usuario: null,
	estudante: null,
	auth: null,
	turmaEstudante: null,
	turma: null,
	curso: null,
	empresas: [],
	polos: [],
	periodos: [],
	projetos: [],
	projetoAtivo: null,
	fases: [],
	marcos: [],
	grupos: [],
	mensagens: [],
	duvidas: [],
	objetivoAprendizagemAtivo: null,
	faseAtual: null,
	showMenuFase: true,
	curriculoEdit: false,
	turmaInteracoes: true,
	avaliacaoState: null,
	respostasAluno: [],
	showNotificacao: false,
	notificacoes: null,
	quantidadeCompetencias: null,
	tusURLs: '',
	selectedCurriculumProfile: null,
	mensagensSecretaria: [],
	documentViewerUrl: '',
	imageViewerUrl: '',
	centralAjuda: '',
} as AppState;

export function reducer(state: AppState = initialState, action: any) {
	let faseAtualObjApreAtv = null;
	let atividadeAtualPelaFaseAtual = null;
	let status = '';
	switch (action.type) {
	case 'FILL_DUVIDAS':
		return { ...state, duvidas: action.payload };
	case 'FILL_INSIDE_DUVIDA':
		return { ...state, insideDuvida: action.payload };
	case 'FILL_TOKEN':
		return { ...state, token: action.payload };
	case 'FILL_FASES':
		return { ...state, fases: action.payload };
	case 'FILL_FALE_COM_TURMA':
		return { ...state, mensagensTurma: action.payload };
	case 'FILL_ID_FORUM_TURMA':
		return { ...state, id_forum_turma: action.payload };
	case 'FILL_MENSAGENS':
		return { ...state, mensagens: action.payload };
	case 'FILL_FASE_ATUAL':
		return { ...state, faseAtual: action.payload };

	case 'FILL_ACTIVE_PROJECT':
		return { ...state, projetoAtivo: action.payload };

	case 'FILL_USER_DATA':
		return { ...state, usuario: action.payload };

	case 'FILL_STUDENT_DATA':
		return { ...state, estudante: action.payload };

	case 'FILL_ENCONTRO_AGENDADO':
		return { ...state, encontroAgendado: action.payload };

	case 'FILL_TURMA_ESTUDANTE':
		return { ...state, turmaEstudante: action.payload };

	case 'FILL_OBJETIVO_APRENDIZAGEM_ATIVO':
		if (!action.payload) return state;

		atividadeAtualPelaFaseAtual = action.payload.atividadeRoteiroTurma[0];
		atividadeAtualPelaFaseAtual.selected = true;
		state.fases.map((fase) => {
			fase.objetivosAprendizagem.map((objetivo) => {
				objetivo.atividadeRoteiroTurma.map((atividade) => {
					atividade.selected =
							atividade.id_atividade_roteiro_turma == atividadeAtualPelaFaseAtual.id_atividade_roteiro_turma;
				});
			});

			if (!fase.aberta) return;

			const fasesObjetivoAtual2 = fase.objetivosAprendizagem.filter(
				(objetivo) => objetivo.id_objetivo_aprendizagem == atividadeAtualPelaFaseAtual.id_objetivo_aprendizagem
			);
			if (fasesObjetivoAtual2.length > 0) faseAtualObjApreAtv = fase;
		});

		return {
			...state,
			objetivoAprendizagemAtivo: action.payload,
			atividadeAtual: atividadeAtualPelaFaseAtual,
			faseAtual: faseAtualObjApreAtv,
		};

	case 'FILL_ATIVIDADE_ATUAL':
		return { ...state, atividadeAtual: action.payload };

	case 'FILL_ENTREGA_ESTUDANTE':
		return { ...state, entregaEstudante: action.payload };

	case 'TOGGLE_INTERACOES':
		return { ...state, showingInteracoes: action.payload };

	case 'SHOW_SAIBA_MAIS':
		return { ...state, showSaibaMais: action.payload };

	case 'FILL_MARCOS':
		return { ...state, marcos: action.payload };

	case 'FILL_NOTIFICACOES':
		return { ...state, notificacoes: action.payload };

	case 'FILL_NOTIFICATION_BADGE':
		return { ...state, notificationBadge: action.payload };

	case 'TOGGLE_AGENDA':
		return { ...state, showingAgenda: action.payload };

	case 'TOGGLE_MENU':
		return { ...state, showingMenu: !state.showingMenu };

	case 'LOGIN_LOADING':
		return { ...state, loginLoading: action.payload };

	case 'ENTREGA_LOADING':
		return { ...state, entregaLoading: action.payload };

	case 'PHOTO_LOADING':
		return { ...state, photoLoading: action.payload };

	case 'INTERACOES_LOADING':
		return { ...state, interacoesLoading: action.payload };

	case 'FILL_LAST_ROUTE':
		return { ...state, lastRoute: action.payload };

	case 'SHOW_MODAL_CONFIGURACOES':
		return { ...state, showModalConfiguracoes: action.payload };

	case 'SHOW_MODAL_PESQUISA':
		return { ...state, showModalPesquisa: action.payload };

	case 'SHOW_MODAL_PESQUISA_SUCESSO':
		return { ...state, showModalPesquisaSucesso: action.payload };

	case 'SHOW_MODAL_FEEDBACK':
		return { ...state, showModalFeedback: action.payload };

	case 'FILL_FORUM_ATUAL':
		return { ...state, forumAtual: action.payload };

	case 'FILL_FORUM_INTERACOES':
		return { ...state, forumInteracoes: action.payload };

	case 'SHOW_TURMA_INTERACOES':
		return { ...state, turmaInteracoes: action.payload };

	case 'SHOW_FORUM_FREE_TALK':
		return { ...state, showForumFreeTalk: action.payload };

	case 'TOGGLE_MENU_FASE':
		if (state.showMenuFase && !action.payload) return { ...state, showMenuFase: false };
		else return { ...state, showMenuFase: true };

	case 'TOGGLE_LOGIN_ALERT':
		return { ...state, loginAlert: action.payload };

	case 'FILL_DADOS_EMPRESA':
		return { ...state, dadosEmpresa: action.payload };

	case 'FILL_DADOS_POLO':
		return { ...state, dadosPolo: action.payload };

	case 'FILL_PROJETOS_ALUNO':
		return { ...state, projetosAluno: action.payload };

	case 'SHOW_CHAT':
		return { ...state, showingChat: action.payload };

	case 'DELAY_ANSWER_OPEN':
		return { ...state, delayAnswerOpen: action.payload };

	case 'CLEAR_STATE':
		return {};

	case 'FILL_TUS_URLS':
		return { ...state, tusURLs: action.payload };

	case 'GET_FROM_SESSION':
		return { ...action.payload };

	case 'FILL_CAT_FORMACAO':
		return { ...state, catFormacao: action.payload };

	case 'FILL_CAT_COMPETENCIA':
		return { ...state, catCompetencia: action.payload };

	case 'FILL_CAT_IDIOMA':
		return { ...state, catIdioma: action.payload };

	case 'FILL_CAT_TESTE_PERFIL':
		return { ...state, catTestePerfil: action.payload };

	case 'FILL_CAT_PERFIL':
		return { ...state, catPerfil: action.payload };

	case 'FILL_CURRICULUM_PROFILE':
		return { ...state, selectedCurriculumProfile: action.payload };

	case 'FILL_CAT_EXPERIENCIA':
		return { ...state, catExperiencia: action.payload };

	case 'TOGGLE_CURRICULO_EDIT':
		return { ...state, curriculoEdit: action.payload };

	case 'SHOW_FORUM_TURMA':
		return { ...state, showForumTurma: action.payload };

	case 'SELECTED_FORUM_ATIVIDADE':
		return { ...state, selectedForumAtividade: action.payload };

	case 'SELECTED_FASE_FORUM':
		return { ...state, selectedFaseForum: action.payload };

	case 'USER_EMAIL':
		return { ...state, userEmail: action.payload };

	case 'SHOW_ENTREGA_ARQUIVO':
		return { ...state, showEntregaArquivo: action.payload };

	case 'AVALIACAO_STATE':
		return { ...state, avaliacaoState: action.payload };

	case 'FILL_PERGUNTA_AVALIACAO':
		return { ...state, perguntaAvaliacao: action.payload };

	case 'FILL_ARRAY_RESPOSTAS_ALUNO':
		return { ...state, respostasAluno: action.payload };

	case 'FILL_DADOS_AVALIACAO':
		return { ...state, dadosAvaliacao: action.payload };

	case 'FILL_TOAST':
		return { ...state, toast: action.payload };

	case 'FILL_CURRICULO_STATE_TEMP':
		return { ...state, curriculoStateTemp: action.payload };

	case 'FILL_SUPORT':
		return { ...state, suport: action.payload };

	case 'FILL_SUPORT_DETAILS':
		return { ...state, suportDetails: action.payload };

	case 'ANTERIORES_LOADING':
		return { ...state, anterioresLoading: action.payload };

	case 'FILL_SOFT_SKILLS':
		return { ...state, softSkills: action.payload };

	case 'FILL_CONTEUDOS':
		return { ...state, conteudos: action.payload };

	case 'FILL_CASOS_SUCESSO':
		return { ...state, casosSucesso: action.payload };

	case 'FILL_EVENTOS':
		return { ...state, eventos: action.payload };

	case 'FILL_MODAL_VIDEO':
		return { ...state, modalVideo: action.payload };

	case 'FILL_CONFIRM_MODAL':
		return { ...state, confirmModal: action.payload };

	case 'SHOW_NOTIFICACAO':
		return { ...state, showNotificacao: action.payload };

	case 'FILL_SECRETARIA_MODAL':
		return { ...state, secretariaModal: action.payload };

	case 'FILL_SELECTED_QUIZ_AVALIACAO':
		return { ...state, selectedQuizAvaliacao: action.payload };

	case 'FILL_SELECTED_QUIZ_DISCURSIVO':
		return { ...state, selectedQuizDiscursivo: action.payload };

	case 'NOTIFICACOES':
		return { ...state, notificacoes: action.payload };

	case 'QUANTIDADE_COMPETENCIAS':
		return { ...state, quantidadeCompetencias: action.payload };

	case 'FILL_AVALIACAO_STAGE':
		return { ...state, avaliacaoStage: action.payload };

	case 'FILL_AVALIACAO_FULL_DATA':
		return { ...state, avaliacaoQuestoes: action.payload };

	case 'FILL_AVALIACAO_QUESTAO':
		return { ...state, avaliacaoQuestao: action.payload };

	case 'FILL_AVALIACAO_QUESTOES':
		return { ...state, avaliacaoQuestoes: action.payload };

	case 'FILL_AVALIACAO_TENTATIVA':
		return { ...state, avaliacaoTentativa: action.payload };

	case 'FILL_AVALIACAO_INDEX':
		return { ...state, avaliacaoIndex: action.payload };

	case 'FILL_AVALIACAO_TIMER':
		return { ...state, avaliacaoTimer: action.payload };

	case 'FILL_AVALIACAO_TYPE':
		return { ...state, avaliacaoType: action.payload };

	case 'FILL_AVALIACAO_ERROR':
		return { ...state, avaliacaoError: action.payload };

	case 'FILL_MENSAGENS_SECRETARIA':
		return { ...state, mensagensSecretaria: action.payload };

	case 'FILL_EMPRESAS':
		return { ...state, empresas: action.payload };

	case 'FILL_POLOS':
		return { ...state, polos: action.payload };

	case 'FILL_ESTUDANTE_STATUS':
		switch (action.payload) {
		case 1:
			status = 'matriculado';
			break;
		case 2:
			status = 'pre-matriculado';
			break;
		case 3:
			status = 'cancelado';
			break;
		case 4:
			status = 'trancado';
			break;
		case 5:
			status = 'aprovado';
			break;
		case 6:
			status = 'reprovado';
			break;
		case 7:
			status = 'trial';
		}
		return { ...state, estudanteStatus: status };

	case 'FILL_DOCUMENT_VIEWER_URL':
		return { ...state, documentViewerUrl: action.payload };
	case 'FILL_IMAGE_VIEWER_URL':
		return { ...state, imageViewerUrl: action.payload };
	case 'FILL_CENTRAL_AJUDA':
		return { ...state, centralAjuda: action.payload };
	default:
		return state;
	}
}
