<div class="overlay" (click)="closeModel(); $event.stopPropagation()">
	<div class="box" (click)="$event.stopPropagation()">
		<div class="close-pattern" (click)="closeModel(); $event.stopPropagation()">
			<!-- <i class="fas fa-times pointer"></i> -->
			<img src="assets/icons/fas-fa-times-pointer-gray.svg" />
		</div>
		<div class="event-tag">
			{{ 'PERFIL.EVENTS' | translate }}
		</div>
		<div class="capa">
			<img [src]="data.capa_evento" class="image image-background" />
			<div class="image overlay-img"></div>
		</div>
		<div class="content">
			<span class="content__nome">{{ data.nome }}</span>
			<div class="content__data">
				<div class="content__data__box">
					<span class="content__data__box__dia">{{ dateFormated.dia }}</span>
					<span class="content__data__box__mes">{{ dateFormated.mes }}</span>
				</div>
				<div class="content__data__hora">
					<span>{{ dateFormated.diaSemana }} {{ dateFormated.hora }}</span>
					<span *ngIf="data.canal_tipo != 1">{{ data.canal_nome }}</span>
					<span>{{ data.local }}</span>
					<span class="content__data__hora__endereco">{{ data.endereco }}</span>
				</div>
			</div>
			<section class="local">
				<div class="tag-row">
					<div
						class="tag-tipo"
						[ngClass]="{
							'tag-tipo__online': data.canal_tipo != 1,
							'tag-tipo__presencial': data.canal_tipo == 1
						}"
					>
						<span>{{ tipoEvento | translate }}</span>
					</div>
					<div class="tag-tipo" *ngIf="data.agenda">
						{{ 'EVENT.SUBSCRIBED' | translate }}
					</div>
					<div class="tag-tipo tag-tipo__atividade" *ngIf="data.atividade_complementar_hora">
						<span>{{ atividade | translate }} | {{ data.atividade_complementar_hora }}h</span>
					</div>
				</div>
			</section>
			<div class="about">
				<span class="about__title">{{ 'EVENT.ABOUT_EVENT' | translate }}</span>
				<span class="about__description" *ngIf="data.descricao" [innerHTML]="data.descricao | sanitizeHtml"></span>
			</div>
			<div *ngIf="showPalestrantesGroup" class="speaker">
				<ng-container *ngFor="let palestrante of data.palestrantes">
					<div *ngIf="palestrante.nome" class="speaker__box">
						<div>
							<img *ngIf="!palestrante.foto" src="../../../assets/imgs/profile-img.svg" class="speaker__box__foto" />
							<img *ngIf="palestrante.foto" [src]="palestrante.foto" class="speaker__box__foto" />
						</div>
						<div class="speaker__box__perfil">
							<div class="speaker__box__perfil__nome">
								{{ palestrante.nome }}
							</div>
							<div class="speaker__box__perfil__cargo">
								{{ palestrante.titulacao_cargo }}
							</div>
							<div class="speaker__box__perfil__minibio">
								{{ palestrante.minibio }}
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<div *ngIf="data.assuntos_abordados" class="subject">
				<span class="subject__title">{{ 'EVENT.COVERED_SUBJECTS' | translate }}</span>
				<div class="subject__body">
					<ul>
						<li *ngFor="let assunto of data.assuntos_abordados.split('\n')">
							{{ assunto }}
						</li>
					</ul>
				</div>
			</div>
			<div class="saiba">
				<ng-container
					*ngIf="
						(data.limitado && data.limite_participantes <= data.inscritos) || data.data_fim < today.format();
						else disponivel
					"
				>
					<button class="btn btn__disabled" id="watch" disabled>
						{{ data.data_fim < today.format() ? ('EVENT.CLOSED' | translate) : ('EVENT.SOLD_OUT' | translate) }}
					</button>
				</ng-container>
				<ng-template #disponivel>
					<ng-container *ngIf="!data.agenda">
						<button class="btn" (click)="agendarEvento()">
							{{ btnInscrever | translate }}
						</button>
					</ng-container>
					<ng-container *ngIf="data.agenda && !releaseLink">
						<button class="btn btn__disabled" id="watch" disabled>
							{{ 'EVENT.ALREADY_SCHEDULED' | translate }}
						</button>
					</ng-container>
					<ng-container *ngIf="data.agenda && data.canal_tipo != 1 && releaseLink">
						<button class="btn" (click)="goToEvent()">
							{{ btnAssistir | translate }}
						</button>
					</ng-container>
					<ng-container *ngIf="data.agenda && data.canal_tipo == 1 && releaseLink">
						<button class="btn" disabled>
							{{ btnPresencial | translate }}
						</button>
					</ng-container>
				</ng-template>
			</div>
		</div>
	</div>
</div>
