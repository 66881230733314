import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';
import { fillAtividadeAtual, showEntregaArquivo, toggleMenuFase } from 'src/app/state/state.actions';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
	selector: 'cls-menu-fase',
	templateUrl: './menu-fase.component.html',
	styleUrls: ['./menu-fase.component.scss'],
})
export class MenuFaseComponent implements OnInit, OnDestroy {
	stateSubscription: any;
	state: any;

	constructor(private store: Store<AppState>, private dataService: DataService) {}

	ngOnInit() {
		this.getState();
	}

	ngOnDestroy(): void {
		this.stateSubscription.unsubscribe();
	}

	getState() {
		this.stateSubscription = this.store
			.select((state: any) => {
				const { faseAtual, atividadeAtual, showMenuFase, showEntregaArquivo, estudante } = state.newAppState;
				this.state = JSON.parse(
					JSON.stringify({
						faseAtual,
						atividadeAtual,
						showMenuFase,
						showEntregaArquivo,
						estudante,
					})
				);
			})
			.subscribe();
	}

	isChecked(atividade) {
		if (atividade.atividadeEstudanteTurma.length > 0 && atividade.atividadeEstudanteTurma[0]?.concluido) return true;
	}

	isMarked(atividade) {
		if (
			this.state.atividadeAtual &&
			atividade.id_atividade_roteiro_turma == this.state.atividadeAtual.id_atividade_roteiro_turma
		)
			return true;
		else return false;
	}

	showAvaliacao(avaliacao = null) {
		this.store.dispatch(showEntregaArquivo({ payload: true }));
		this.store.dispatch(fillAtividadeAtual({ payload: null }));
	}

	showAtividades(objetivo) {
		if (!objetivo.show_atividades) {
			objetivo.show_atividades = true;
			objetivo.show_icon = true;
		} else {
			objetivo.show_atividades = false;
			setTimeout(() => {
				objetivo.show_icon = false;
			}, 500);
		}
	}

	toggleEntrega(entrega) {
		entrega.show_entrega = !entrega.show_entrega;
	}

	toggleAtividade(atividade, objetivo) {
		if (
			atividade.atividadeEstudanteTurma.length > 0 &&
			atividade.atividadeEstudanteTurma[0]?.concluido == 1 &&
			this.state.estudante.id_projeto_turma_estudante_status == 1
		)
			this.dataService.completeAtividade(objetivo, atividade, 0);
		else this.dataService.completeAtividade(objetivo, atividade, 1);
	}

	toggleMenu() {
		this.store.dispatch(toggleMenuFase({ payload: null }));
	}

	clearAtividadeAtual() {
		this.store.dispatch(showEntregaArquivo({ payload: false }));
		this.store.dispatch(fillAtividadeAtual({ payload: null }));
	}

	calculatePercentage(objetivo) {
		return (objetivo.totalAtividadesConcluidas * 100) / objetivo.totalAtividades;
	}

	setAtividadeAtual(atividade) {
		if (this.state.atividadeAtual != atividade) {
			this.scrollToContent();
			this.store.dispatch(fillAtividadeAtual({ payload: atividade }));
			this.store.dispatch(showEntregaArquivo({ payload: false }));
		}
	}

	scrollToContent() {
		setTimeout(() => {
			window.scrollTo({
				top: 460,
				behavior: 'smooth',
			});
		}, 300);
	}
}
